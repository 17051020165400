<template>
  <b-card>
    <validation-observer ref="profilForm">
      <b-form>
        <b-row>
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="email"
                disabled
              />
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Numéro de téléphone"
              label-for="tel"
            >
              <validation-provider
                #default="{ errors }"
                name="tel"
                rules="required"
                :state="errors.length > 0 ? false:null"
              >
                <b-form-input
                  id="tel"
                  v-model="tel"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Nom"
              label-for="nom"
            >
              <validation-provider
                #default="{ errors }"
                name="nom"
                rules="required"
              >
                <b-form-input
                  id="nom"
                  v-model="nom"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Prénom"
              label-for="prenom"
            >
              <validation-provider
                #default="{ errors }"
                name="prenom"
                rules="required"
              >
                <b-form-input
                  id="prenom"
                  v-model="prenom"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            v-if="canHaveSociete"
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Société"
              label-for="societe"
            >
              <b-form-input
                id="societe"
                v-model="societe"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="12">
            <hr>
            <h4>Modifier votre mot de passe</h4>
            <b-alert
              :show="differentPassword"
              variant="danger"
            >
              <div class="alert-body">
                <span>Les mots de passe sont differents</span>
              </div>
            </b-alert>
          </b-col>

          <b-col sm="6">
            <b-form-group
              label="Nouveau mot de passe"
              label-for="password"
            >
              <b-form-input
                id="password"
                v-model="password"
                type="password"
              />
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              label="Confirmer votre nouveau mot de passe"
              label-for="confirmPassword"
            >
              <b-form-input
                id="confirmPassword"
                v-model="confirmPassword"
                type="password"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div class="text-right">
          <b-button @click="update()">
            Mettre à jour
          </b-button>
        </div>
      </b-form>
    </validation-observer>

  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BCard, BFormGroup, BFormInput, BButton, BForm, BAlert,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BAlert,
  },
  data() {
    return {
      userId: null,
      email: '',
      nom: '',
      prenom: '',
      tel: '',
      societe: '',
      password: '',
      confirmPassword: '',
      canHaveSociete: false,
      required,
      errors: [],
      differentPassword: false,
    }
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem('userData'))
    this.userId = user.id
    this.email = user.email
    this.nom = user.nom
    this.prenom = user.prenom
    this.tel = user.tel

    if (user.role.includes('ROLE_CONSTRUCTEUR') || user.role.includes('ROLE_ADMIN')) {
      this.societe = user.societe
      this.canHaveSociete = true
    }
  },
  methods: {
    update() {
      const that = this
      this.differentPassword = false
      if (this.password !== '') {
        if (this.password !== this.confirmPassword) {
          this.differentPassword = true
        }
      }
      if (this.differentPassword === false) {
        this.$refs.profilForm.validate().then(success => {
          console.log(success)
          if (success) {
            const data = {
              id: this.userId,
              nom: this.nom,
              prenom: this.prenom,
              tel: this.tel,
              societe: this.societe,
            }
            if (this.password !== '') data.password = this.password

            that.$store.dispatch('auth/updateProfil', data).then(() => {
              that.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Vos modifications ont bien été prise en compte',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
            })
          }
        })
      }
    },
  },
}
</script>
